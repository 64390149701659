body {
    background-color: #ffffff42;
}

.login-background {
    position: relative;
    width: 100%;
    height: 100vh;
}

.shape {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}

.shape:first-child {
    background: linear-gradient(#1845ad, #23a2f6);
    left: -80px;
    top: -80px;
}

.shape:last-child {
    background: linear-gradient(to right, #ff512f, #f09819);
    right: -30px;
    bottom: -80px;
}

.left-section {
    height: 820px; 
    width: 700px;  
    background-color: rgba(255, 255, 255, 0.13); 
    position: absolute; 
    transform: translate(-50%, -50%);  /* Centrage */
    top: 50%;  /* Alignement vertical */
    left: 50%;  /* Positionnement horizontal */
    backdrop-filter: blur(10px);  /* Effet de flou */
    padding: 50px 35px;  /* Espacement intérieur */
    display: flex;  /* Utilisation de flexbox */
    flex-direction: column;  /* Disposition des éléments de haut en bas */
    align-items: flex-start;  /* Alignement à gauche */
    justify-content: flex-start;  /* Justification au début (en haut) */
    gap: 20px;  /* Espacement entre les éléments */
}




.logo {
    max-width: 200px; /* Largeur maximale de l'image */
    height: auto; /* Hauteur automatique pour conserver les proportions */
}

.login-form {
    height: 520px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 25%; 
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(5, 1, 39, 0.6);
    padding: 50px 35px;
}

.login-form * {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.login-form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    color :#1845ad; /* Couleur du titre */
}

.login-form label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
}

.login-form input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
    color: #0f0f0f; /* Couleur du texte */
    border: 2px solid rgba(20, 7, 139, 0.3);
}

.login-form button {
    margin-top: 50px;
    width: 100%;
    background-color: #1845ad;
    color: #ffffff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}



@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');  /* Importer une police personnalisée */

@keyframes simple-blink {
  50% {
    opacity: 0.5;  /* Demi-transparence pour clignotement */
  }
}

.custom-text {
  font-family: 'Courier', fantasy;  /* Changer la police */
  font-size: 24px;  /* Taille de la police */
  font-weight: 500;  /* Poids de la police */
  color: #1845ad;  /* Couleur orange pour le texte */
  animation: simple-blink 2s infinite;  /* Animation de clignotement */
}
