/* Styles for custom file input */
.file-input {
    display: inline-block;
    position: relative;
    width: 130px;
    --font-size: 15px;
    --padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Ajoutez une transition pour l'animation */
}

.file-input input[type=file] {
    width: 100%;
    cursor: pointer;
}

.file-input input[type=file]::file-selector-button {
    display: block;
    opacity: 0;
    margin-bottom: 5px;
    font-size: var(--font-size);
    padding: var(--padding);
    cursor: pointer;
}

.file-input::before {
    font-size: var(--font-size);
    padding: var(--padding);
    content: "Import excel";
    border-radius: 5px;
    background: #fff;
    border: 1px solid white;
    color: black;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Ajoutez une règle pour le hover */
.file-input:hover::before {
    background-color: #adadad; /* Gris foncé */
}
